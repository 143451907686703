import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ElLoading } from 'element-plus';

let Loading: any;
let error: any;
const startLoading = () => {
  interface Options {
    lock: boolean;
    text: string;
    background: string;
  }
  const options: Options = {
    lock: true,
    text: "加载中...",
    background: 'rgba(0,0,0,0.7)'
  }
  Loading = ElLoading.service(options);
}

const endLoading = () => {
  Loading.close();
}

//请求拦截
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  //加载
  startLoading();
  return config;
})

axios.interceptors.response.use((response: AxiosResponse<any>) => {
  //加载结束
  endLoading();
  return response; 
}), (error: any) => {
  endLoading();
  return Promise.reject(error);
}
export default axios;