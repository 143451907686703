import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/404.vue')
  },
  {
    path: '/item_manage/:source/:value',
    name: 'item_manage',
    component: () => import('../views/item_manage/index.vue')
  },

  // {
  //   path: '/:catchAll(.*)',
  //   name: '/404',
  //   component: () => import('../views/404.vue')
  // }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router