<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<style>
* {
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
  -webkit-appearance: none;
}
body {
  margin: 0;
  background-color: #f2f6fc;
  overflow-x: hidden;

}
#app {
  width: 100%;
  /* max-width: 600px; */
  overflow-x: hidden;
  border: 1px rgba(199, 174, 174, 0.315) solid;
  height: calc(100% - 2px);
  margin: 0 auto;
}
</style>
 
