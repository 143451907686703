import { createStore } from "vuex";
import axios from "axios";
import router from "@/router/index";

export default createStore({
  //数据存储属性
  state: {
    edit_mode: false, //编辑模式开关
    show_search: false, //搜索框开关
    item_list: [], //订单内容
    active_item_list: [], //活动的订单内容（筛选什么的）
    current_customer: "",
    source: "",
    search_key: "",
    select_item: [],
    select_image: [],
    change_quantity:[],
    current_source:[],
    show_recycle:false
  },

  //方法属性
  mutations: {
    show_search(state, val) {
      //搜索框开关
      state.show_search = val;
    },

    set_active_item(state, val) {
      //更新活动的item_list
      state.active_item_list = state.item_list[val];
      state.current_customer = state.item_list[val][0]["cid"];
    },
  },

  //异步属性
  actions: {
    update_item_list(state, str) {
      //更新item_list
      let url=`https://apitest.inkinp.com/edit.getItemList?${str.source=="from_customer_id"?"TBID":"itemID"}=${str.search_key}`
      console.log(url);
      axios
        .get(
          url,
          {}
        )
        .then((res) => {
          this.state.item_list = res.data.data;
          let indexCustomer = Object.keys(res.data.data)[0];
          this.state.current_customer = indexCustomer;
          this.state.active_item_list = res.data.data[indexCustomer];
          this.state.show_search = false;
          // @ts-ignore
          this.state.current_source={source: str.source, value: str.search_key},
          router.replace({
            name: "item_manage",
            params: { source: str.source, value: str.search_key },
          }); //地址栏地址替换
        })
        .catch((err) => {
          console.error("获取数据失败" + err);
        });
    },
  },

  modules: {},
});
